<template>
  <v-row v-if="current"
         justify="center">
    <v-col class="mt-5"
           cols="10"
           md="5">
        <h1>Guía</h1>
        <markdown-component :source="current.text" />
        <v-row class="mt-10 mb-10" justify="center">
          <v-btn v-if="!isFirstGuide"
                 @click="back"
                 color="primary"
                 class="ml-3"
                 outlined>Atras</v-btn>
          <v-btn v-if="isLastGuide"
                 @click="ended"
                 class="ml-3"
                 color="primary">Continuar</v-btn>
          <v-btn v-else
                 @click="next"
                 class="ml-3"
                 color="primary">Siguiente</v-btn>
        </v-row>
    </v-col>
  </v-row>
</template>

<script>
import GuideLogic from './guide-logic.vue';
import MarkdownComponent from '../../../components/markdown-component.vue';

export default {
  name: 'guide-basic-component',
  extends: GuideLogic,
  components: { MarkdownComponent },
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
}
div.actions {
  margin-bottom: 10%;
}
</style>
